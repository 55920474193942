<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Company</label>
            <b-select
              v-model="filter.company"
              :options="filterCompanies"
              @change="onTableRefresh"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search Employee</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            New Employee
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        hover
        responsive
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              :disabled="state.busy"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="secondary"
              :disabled="state.busy"
              @click="onResetPassword(row.item)"
            >
              Reset Password
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              size="sm"
              :options="tableSettings.pageOptions"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-employee-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="username"
            vid="username"
            rules="max:30"
          >
            <b-form-group>
              <label for="username">Username</label>
              <b-input
                id="username"
                v-model="employee.username"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter username"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="email"
            vid="email"
            rules="email|max:100"
          >
            <b-form-group>
              <label for="email">Email</label>
              <b-input
                id="email"
                v-model="employee.email"
                type="email"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter email address"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="passwordRule"
          >
            <b-form-group>
              <label for="password">Password <small v-if="state.editing">(Leave blank when not changing password.)</small></label>
              <b-input
                id="password"
                v-model="employee.password"
                type="password"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter password"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password confirmation"
            vid="password_confirmation"
            :rules="passwordConfirmationRule"
          >
            <b-form-group>
              <label for="password_confirmation">Password Confirmation <small v-if="state.editing">(Leave blank when not changing password.)</small></label>
              <b-input
                id="password_confirmation"
                v-model="employee.password_confirmation"
                type="password"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter password confirmation"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="employee code"
            vid="employee_code"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="employee_code">Employee Code</label>
              <b-input
                id="employee_code"
                v-model="employee.employee_code"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter employee code"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="first name"
            vid="first_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="first_name">First Name</label>
              <b-input
                id="first_name"
                v-model="employee.first_name"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter first name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="middle name"
            vid="middle_name"
            rules="max:50"
          >
            <b-form-group>
              <label for="middle_name">Middle Name</label>
              <b-input
                id="middle_name"
                v-model="employee.middle_name"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter middle name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="last name"
            vid="last_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="last_name">Last Name</label>
              <b-input
                id="last_name"
                v-model="employee.last_name"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter last name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="suffix name"
            vid="suffix_name"
            rules="max:15"
          >
            <b-form-group>
              <label for="suffix_name">Suffix Name</label>
              <b-input
                id="suffix_name"
                v-model="employee.suffix_name"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter suffix name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="company"
            vid="company"
            rules="required"
          >
            <b-form-group>
              <label for="company">Company</label>
              <b-form-select
                id="company"
                v-model="employee.company"
                :options="list.companies"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select company --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="organization"
            vid="organization"
            rules="required"
          >
            <b-form-group>
              <label for="organization">Organization</label>
              <b-form-select
                id="organization"
                v-model="employee.organization"
                :options="list.organizations"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select organization --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="position"
            vid="position"
            rules="required"
          >
            <b-form-group>
              <label for="position">Position</label>
              <b-form-select
                id="position"
                v-model="employee.position"
                :options="list.positions"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select position --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="active"
              v-model="employee.active"
              name="active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>

      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, UserEmployeeService, UserPasswordResetService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserEmployees',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Employees'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      list: {
        companies: [],
        organizations: [],
        positions: []
      },
      filter: {
        company: 'All',
        organization: 'All'
      },
      employee: {
        id: 0,
        active: 1,
        username: null,
        email: null,
        password: '',
        password_confirmation: '',
        employee_code: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix_name: '',
        company: null,
        organization: null,
        position: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'email', sortable: true },
          { key: 'employee_code', sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'company_name', label: 'company' },
          { key: 'organization_name', label: 'organization' },
          { key: 'position_name', label: 'position' },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Employee' : 'New Employee'
    },

    passwordRule () {
      return this.state.editing && this.employee.password?.length <= 0 ? {} : {
        required: true,
        min: 8,
        confirmed: 'password_confirmation'
      }
    },

    passwordConfirmationRule () {
      return this.state.editing && this.employee.password?.length <= 0 ? {} : {
        required: true
      }
    },

    filterCompanies () {
      return [{ value: 'All', text: 'All' }].concat(this.list.companies)
    }
  },

  watch: {
    'employee.company' (value) {
      if (value) {
        if (!this.state.editing) {
          this.employee.organization = null
          this.employee.position = null
        }
        this.fetchOrganizationList(value)
        this.fetchPositionList(value)
      }
    }
  },

  mounted () {
    core.index()
    this.fetchCompanyList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserEmployeeService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_company=${this.filter.company}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    },

    async fetchCompanyList () {
      await SharedListService.getCompanies().then(({ data }) => {
        this.list.companies = data.map(company => ({
          value: company.id,
          text: company.company_name
        }))
      })
    },

    async fetchOrganizationList (companyId) {
      await SharedListService.getOrganizations(companyId).then(({ data }) => {
        this.list.organizations = data.map(organization => ({
          value: organization.id,
          text: organization.organization_name
        }))
      })
    },

    async fetchPositionList (companyId) {
      await SharedListService.getPositions(companyId).then(({ data }) => {
        this.list.positions = data.map(position => ({
          value: position.id,
          text: position.position_name
        }))
      })
    },

    onShowAddForm () {
      this.state.editing = false
      this.employee.id = 0
      this.employee.active = 1
      this.employee.username = ''
      this.employee.email = ''
      this.employee.password = ''
      this.employee.password_confirmation = ''
      this.employee.employee_code = ''
      this.employee.first_name = ''
      this.employee.middle_name = ''
      this.employee.last_name = ''
      this.employee.suffix_name = ''
      this.employee.company = null
      this.employee.organization = null
      this.employee.position = null
      this.$bvModal.show('modal-employee-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.employee.id = current.id
      this.employee.active = current.active
      this.employee.username = current.username
      this.employee.email = current.email
      this.employee.password = ''
      this.employee.password_confirmation = ''
      this.employee.employee_code = current.employee_code
      this.employee.first_name = current.first_name
      this.employee.middle_name = current.middle_name
      this.employee.last_name = current.last_name
      this.employee.suffix_name = current.suffix_name
      this.employee.company = current.company_id
      this.employee.organization = current.organization_id
      this.employee.position = current.position_id
      this.$bvModal.show('modal-employee-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create employee?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserEmployeeService.post(this.employee).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-employee-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.onTableRefresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserEmployeeService.put(this.employee).then(({ data }) => {
          this.$bvModal.hide('modal-employee-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: this.employee.id })
            row.active = data.employee.active
            row.username = data.employee.username
            row.email = data.employee.email
            row.employee_code = data.employee.employee_code
            row.first_name = data.employee.first_name
            row.middle_name = data.employee.middle_name
            row.last_name = data.employee.last_name
            row.suffix_name = data.employee.suffix_name
            row.company = data.employee.company_id
            row.organization = data.employee.organization_id
            row.position = data.employee.position_id
            row.updated_at = data.employee.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onResetPassword (current) {
      this.employee.id = current.id
      this.employee.first_name = current.first_name
      this.employee.middle_name = current.middle_name
      this.employee.last_name = current.last_name

      if (current) {
        this.$swal.fire({
          icon: 'question',
          title: 'Do you really want to reset the password of this employee?',
          text: `[ ${current.first_name} ${current.middle_name} ${current.last_name} ]`,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Yes',
          cancelButtonColor: '#FF2929',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.onPutResetPassword()
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        })
      }
    },

    async onPutResetPassword () {
      return new Promise(resolve => {
        this.state.busy = true

        const params = {
          id: this.employee.id
        }

        UserPasswordResetService.put(params).then(({ data }) => {
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
